import { useTranslation } from 'react-i18next';
import { BusinessAwardStyles } from './styles';

const BusinessAwards = () => {
  const { t } = useTranslation();

  return (
    <BusinessAwardStyles>
      <div className="flex-center">
        <img src="/images/business-awards.png" alt="business-awards" />
      </div>
      <div className="text-center size-18 desc-color fw-400">
        {t('description.featuredBy')}
      </div>
    </BusinessAwardStyles>
  );
};

export default BusinessAwards;
