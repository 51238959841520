import styled from 'styled-components';

export const SubsidyCalculatorCTAStyles = styled.div`
  background: #def1ff;
  border-radius: 32px;
  overflow: hidden;

  @media only screen and (max-width: 576px) {
    border-radius: 24px;
  }
`;

export const SubsidyImgBannerStyle = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -16%);
  min-width: 60vh;
`;

export const SubsidyDescriptionStyle = styled.div`
  > div:not(:last-child) {
    margin-bottom: 10px;
  }
  max-width: 601px;

  @media only screen and (max-width: 576px) {
    line-height: 24px;
  }
`;

export const SubsidyCalculatorContentStyles = styled.div`
  padding: 48px 0px 48px 48px;

  @media only screen and (max-width: 768px) {
    padding: 20px 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;
