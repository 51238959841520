import styled from 'styled-components';

export const FeaturedByStyles = styled.div`
  width: 100%;
  max-width: 100vw;
  --marginY: 50px;
  --title-font-size: 48px;
  --title-margin-bottom: 60px;

  margin: var(--marginY) 0;

  .feature-title {
    font-size: var(--title-font-size);
    font-weight: 700;
    margin-bottom: var(--title-margin-bottom);
  }

  @media only screen and (max-width: 992px) {
    --marginY: 80px;
    --title-font-size: 32px;
    --title-margin-bottom: 45px;
  }

  @media only screen and (max-width: 768px) {
    --marginY: 60px;
    --title-margin-bottom: 32px;
  }

  @media only screen and (max-width: 576px) {
    --marginY: 40px;
    --title-font-size: 30px;
    --title-margin-bottom: 27px;
  }

  @media only screen and (max-width: 480px) {
    --title-font-size: 28px;
  }

  .marquee-container .overlay {
    z-index: unset !important;
  }

  .feature-image {
    padding: 0;
    transition: transform 0.5s;

    :hover {
      transform: scale(1.1);
    }
  }
`;

export const ImageItemStyles = styled.div`
  .feature-image {
    padding: 20px 16px 20px 16px;
    transition: transform 0.5s;
    :hover {
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 500px) {
    .feature-image {
      padding: 15px 65px 15px 65px;
    }
  }
`;
