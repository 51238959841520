import { useGetAllBrandsQuery } from '@redux/brands';
import {
  getCurrentGeoLocation,
  getRehydratedPersistConfig,
} from '@redux/config/selectors';
import FeatureTitle from '@uikit/commons/FeatureTitle';
import BrandItem from '@uikit/items/BrandItem';
import Slide from '@uikit/sections/Slide';
import { useDispatch, useSelector } from 'react-redux';
import {
  BRAND_SLIDE_SETTINGS,
  CAROUSEL_HOME_KEYS,
  MAX_LIMIT_PER_PAGE,
  SEARCH_BY_BRAND_PATH,
} from 'configs/constants';
import { useEffect } from 'react';
import { setCarouselHomeLoadedByKey } from '@redux/configLocal/slice';
import { HomeSectionStyles } from './styles';
import SkeletonCarousel from './SkeletonCarousel';

const SearchByBrand = () => {
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  const persistConfig = useSelector(getRehydratedPersistConfig);
  const dispatch = useDispatch();

  const { data, isLoading, isError, isSuccess } = useGetAllBrandsQuery(
    {
      data: {
        offset: 0,
        limit: MAX_LIMIT_PER_PAGE,
        ...currentGeoLocation,
      },
      options: { isDefaultOrderBy: false },
    },
    {
      skip: !persistConfig,
    },
  );

  useEffect(() => {
    if (isSuccess || isError) {
      dispatch(
        setCarouselHomeLoadedByKey({
          key: CAROUSEL_HOME_KEYS.brands,
          value: true,
        }),
      );
    }
  }, [dispatch, isSuccess, isError]);

  if (isLoading) return <SkeletonCarousel />;

  return data?.results?.length ? (
    <HomeSectionStyles>
      <FeatureTitle
        title="home.searchByBrand"
        subTitle="home.searchByBrandDesc"
        path={{
          pathname: SEARCH_BY_BRAND_PATH,
        }}
      />

      <Slide
        autoplay={false}
        {...BRAND_SLIDE_SETTINGS}
        className="brands-slide"
      >
        {data?.results?.map((item) => (
          <BrandItem item={item} key={item?.id} />
        ))}
      </Slide>
    </HomeSectionStyles>
  ) : null;
};

export default SearchByBrand;
