import { Avatar, Space } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { getFeaturedImage } from 'utils/dataUtils';
import TagCustom from '@uikit/commons/TagCustom';
import { useMemo } from 'react';
import { CentreItem } from 'types/centres';
import KindiRating from '@uikit/commons/KindiRating';
import CoverImageRatio from '@uikit/items/CoverImageRatio';
import FavoriteButton from '@uikit/items/CentreItem/FavoriteButton';
import { BannerCentreItemStyles } from './styles';

interface Props {
  item?: Partial<CentreItem>;
  showLogo?: boolean;
  fullWidth?: boolean;
}

const ServiceItemBanner = ({ item, showLogo, fullWidth }: Props) => {
  const tagFeature = useMemo(() => {
    if (item?.isFeatured) {
      return (
        <TagCustom
          color="#fff"
          backgroundColor="var(--primary)"
          title="centres.featured"
        />
      );
    }
    if (item?.isSpecialOffer) {
      return (
        <TagCustom
          color="#fff"
          backgroundColor="var(--primary)"
          title="centres.specialOffer"
        />
      );
    }
    return null;
  }, [item]);

  const centreLogo = useMemo(() => {
    if (item?.brand?.logo || item?.logo?.url) {
      return (
        <Avatar
          className="centre-item__logo"
          alt="kindicare"
          src={item?.brand?.logo || item?.logo?.url}
          size={80}
          icon={<PictureOutlined />}
        />
      );
    }

    return null;
  }, [item]);

  return (
    <BannerCentreItemStyles fullWidth={fullWidth}>
      <div className="centre-item__cover-wrapper">
        <CoverImageRatio
          fullWidth={fullWidth}
          src={item?.featuredImageUrl || getFeaturedImage(item?.images)}
        />
        <Space wrap className="centre-item__group-tag">
          <KindiRating
            className="block-btn centre-item__rating-btn"
            kindiCareRating={item?.kindiCareRating}
            kindiCareRatingImage={item?.kindiCareRatingImage}
            width={38}
            height={38}
          />
          {tagFeature}
        </Space>
        <FavoriteButton centreId={item?.id} />
        {item?.NQSRType?.image && (
          <img
            className="centre-item__NQSRType-img"
            alt="kindicare"
            src={item?.NQSRType?.image}
          />
        )}
        {showLogo && centreLogo}
      </div>
    </BannerCentreItemStyles>
  );
};

export default ServiceItemBanner;
