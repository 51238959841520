import { Row } from 'antd';
import { ImageItemStyles } from '@uikit/sections/FeaturedBy/styles';
import FeaturedImage from './FeaturedImage';

interface Props {
  items: string[];
}

const FeaturedByItems = ({ items }: Props) => (
  <ImageItemStyles>
    <Row className="flex-center">
      {items.map((item, index) => (
        <FeaturedImage logo={item} key={index} />
      ))}
    </Row>
  </ImageItemStyles>
);

export default FeaturedByItems;
