import { useSelector } from 'react-redux';
import { useGetAllCentresFeaturedQuery } from '@redux/centresFeatured';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { getUserParamsFilterSelector } from '@redux/auth/selectors';
import { getIsLoadedTopCarouselHome } from '@redux/configLocal/selectors';
import CentreSlide from './CentresSlide';

interface Props {
  location?: {
    latitude: number;
    longitude: number;
  };
  suburb?: string;
}

const FeaturedSpecialOffers = ({ location, suburb }: Props) => {
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  const userParams = useSelector(getUserParamsFilterSelector);
  const isLoadedTopCarouselHome = useSelector(getIsLoadedTopCarouselHome);

  const { data, isLoading } = useGetAllCentresFeaturedQuery(
    {
      data: {
        limit: 12,
        offset: 0,
        ...userParams,
        suburb,
        ...(location ?? currentGeoLocation),
      },
      options: { isDefaultOrderBy: false },
    },
    {
      skip: !isLoadedTopCarouselHome,
    },
  );

  return (
    <CentreSlide
      title="home.featuredSpecialOffers"
      subTitle="home.featuredSpecialOffersDesc"
      path="/centres-featured-special-offers"
      data={data}
      isLoading={isLoading || !isLoadedTopCarouselHome}
    />
  );
};

export default FeaturedSpecialOffers;
