import { Col, Row } from 'antd';
import { useGetAllCentresPopularQuery } from '@redux/centresPopular';
import { useGetAllCentresRecentSearchesQuery } from '@redux/centresRecentSearches';
import { useGetAllCitiesQuery } from '@redux/cities';
import { useSelector } from 'react-redux';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { SEARCH_TYPES_CONST } from 'configs/localData';
import { CAPITAL_CITIES_DATA } from 'data/capitalCitiesMockup';
import CoveringItem from './CoveringItem';
import { CoveringCentresStyles } from './styles';

const CoveringCentres = () => {
  const currentGeoLocation = useSelector(getCurrentGeoLocation);

  const { data: popularCentres } = useGetAllCentresPopularQuery({
    data: {
      limit: 8,
      offset: 0,
      ...currentGeoLocation,
    },
  });

  const { data: cities } = useGetAllCitiesQuery({
    data: {
      limit: 8,
      offset: 0,
    },
  });

  const { data: centresRecentSearches } = useGetAllCentresRecentSearchesQuery({
    data: {
      limit: 8,
      offset: 0,
    },
  });

  return (
    <CoveringCentresStyles>
      <div className="wrapper-container">
        <Row gutter={[16, 44]}>
          <Col lg={6} md={12} xs={12}>
            <CoveringItem
              title="title.popularCentres"
              data={popularCentres?.results}
              type={SEARCH_TYPES_CONST.centre.value}
            />
          </Col>
          <Col lg={6} md={12} xs={12}>
            <CoveringItem
              title="title.recentSearches"
              data={centresRecentSearches?.results}
              type={SEARCH_TYPES_CONST.centre.value}
            />
          </Col>
          <Col lg={6} md={12} xs={12} className="custom-col-12">
            <CoveringItem
              title="title.suburbs"
              data={cities?.results}
              type={SEARCH_TYPES_CONST.city.value}
            />
          </Col>
          <Col lg={6} md={12} xs={12} className="custom-col-12">
            <CoveringItem
              title="title.capitalCities"
              data={CAPITAL_CITIES_DATA}
              type={SEARCH_TYPES_CONST.city.value}
            />
          </Col>
        </Row>
      </div>
    </CoveringCentresStyles>
  );
};

export default CoveringCentres;
