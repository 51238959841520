import CoveringCentres from './CoveringCentres';
import CoveringProviders from './CoveringProviders';

const CoveringProvidersCentres = () => (
  <div>
    <CoveringProviders />
    <CoveringCentres />
  </div>
);

export default CoveringProvidersCentres;
