import PropTypes from 'prop-types';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  capitalize,
  getLinkCentreDetail,
  getLinkCentresState,
  getLinkCentresSuburb,
} from 'utils/tools';
import { SEARCH_TYPES_CONST } from 'configs/localData';
import Link from '@uikit/commons/Link';
import { CoveringItemStyles } from './styles';

const CoveringItem = ({ title, data, type }) => {
  const { t } = useTranslation();

  const getHrefItem = (item) => {
    switch (type) {
      case SEARCH_TYPES_CONST.centre.value:
        return getLinkCentreDetail(item);

      case SEARCH_TYPES_CONST.city.value:
        return getLinkCentresSuburb(item);

      case SEARCH_TYPES_CONST.state.value:
        return getLinkCentresState(item);

      default:
        return '#';
    }
  };

  return (
    <CoveringItemStyles>
      <div className="covering-item-title fw-bold size-18 text-highlight-secondary">
        {t(title)}
      </div>
      <Space className="menu-space-item mt-20" direction="vertical" size={20}>
        {data?.map((item, idx) => (
          <Link key={String(idx)} href={getHrefItem(item)}>
            <div className="covering-item-link link size-l text-secondary text-capitalize">
              {item?.name || capitalize(item?.city)}
            </div>
          </Link>
        ))}
      </Space>
    </CoveringItemStyles>
  );
};

CoveringItem.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.oneOf(['centre', 'city', 'state']),
};

export default CoveringItem;
