import { getUserParamsFilterSelector } from '@redux/auth/selectors';
import { useGetAllCentresFavouritesQuery } from '@redux/centresFavourites';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { getIsLoadedTopCarouselHome } from '@redux/configLocal/selectors';
import { useSelector } from 'react-redux';
import CentreSlide from './CentresSlide';

const CentresFavourites = () => {
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  const userParams = useSelector(getUserParamsFilterSelector);
  const isLoadedTopCarouselHome = useSelector(getIsLoadedTopCarouselHome);

  const { data, isLoading } = useGetAllCentresFavouritesQuery(
    {
      data: {
        limit: 12,
        offset: 0,
        ...userParams,
        ...currentGeoLocation,
      },
      options: { isDefaultOrderBy: false },
    },
    {
      skip: !isLoadedTopCarouselHome,
    },
  );

  return (
    <CentreSlide
      title="home.yourFavourites"
      subTitle="home.yourFavouritesDesc"
      path="/favourites-centers"
      data={data}
      isLoading={isLoading || !isLoadedTopCarouselHome}
    />
  );
};

export default CentresFavourites;
