import FeatureTitle from '@uikit/commons/FeatureTitle';
import { QuoteUpIcon } from '@uikit/commons/SVGIcons';
import { TESTIMONIALS_DATA } from 'data/testimonialsMockup';
import Arrow from './Arrow';
import { TestimonialSlide, TestimonialsStyles } from './styles';
import TestimonialItem from './TestimonialItem';

const Testimonials = () => (
  <TestimonialsStyles>
    <FeatureTitle title="home.testimonial" subTitle="home.testimonialDesc" />

    <div className="stacking-context">
      <div className="testimonial-wrapper">
        <div className="quote-up">
          <QuoteUpIcon />
        </div>
        <div className="testimonial-content">
          <TestimonialSlide
            dots={false}
            arrows
            nextArrow={<Arrow type="right" />}
            prevArrow={<Arrow type="left" />}
          >
            {TESTIMONIALS_DATA.map((item, index) => (
              <TestimonialItem {...item} key={String(index)} />
            ))}
          </TestimonialSlide>
          <img
            src="/images/banners/testimonial-banner.png"
            className="testimonial-banner"
            alt="testimonial banner"
          />
        </div>
      </div>
    </div>
  </TestimonialsStyles>
);

export default Testimonials;
