import styled from 'styled-components';

export const DownloadStyles = styled.div`
  margin-top: 100px;
  .title-logo {
    color: #2d1f21;
    font-size: 32px;
  }
  .desc-app {
    color: #352f4a;
    line-height: 32px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 0.02em;
  }
  .learn-more-color {
    color: #32a4fc;
  }

  @media only screen and (max-width: 677px) {
    .title-logo {
      font-size: 30px;
    }
    .desc-app {
      font-size: 17px;
    }
    .image-logo {
      img {
        width: 150px !important;
      }
    }
  }
  @media only screen and (max-width: 576px) {
    .title-logo {
      font-size: 30px;
    }
    .desc-app {
      font-size: 16px;
    }
    .image-logo {
      margin-top: 5px;
      img {
        width: 130px !important;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .title-logo {
      font-size: 25px;
    }
    .desc-app {
      font-size: 15px;
    }
    .image-logo {
      margin-top: 1px;
      img {
        width: 110px !important;
      }
    }
  }

  @media only screen and (max-width: 430px) {
    .title-logo {
      font-size: 23px;
    }
    .desc-app {
      font-size: 15px;
    }

    @media only screen and (max-width: 397px) {
      .title-logo {
        font-size: 20px;
      }
      .desc-app {
        font-size: 14px;
      }
    }
  }
`;
