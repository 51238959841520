import {
  LeftLightArrowIcon,
  RightLightArrowIcon,
} from '@uikit/commons/SVGIcons';
import { Button } from 'antd';
import { omit } from 'lodash-es';
import PropTypes from 'prop-types';

const Arrow = ({ type, ...props }) => (
  <Button
    {...omit(props, ['currentSlide', 'slideCount'])}
    size="small"
    shape="circle"
    icon={type === 'left' ? <LeftLightArrowIcon /> : <RightLightArrowIcon />}
  />
);
Arrow.propTypes = {
  currentSlide: PropTypes.number,
  type: PropTypes.oneOf(['left', 'right']),
};

export default Arrow;
