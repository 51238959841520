/* eslint-disable no-nested-ternary */
import CentreAddressDistance from '@uikit/commons/CentreAddressDistance';
import { Avatar, Card } from 'antd';
import Link from '@uikit/commons/Link';
import { getLinkCentreDetail } from 'utils/tools';
import CentreServiceInfo from '@uikit/commons/CentreServiceInfo';
import withTrackingImpression from 'hocs/withTrackingImpression';
import { CentreItem } from 'types/centres';
import CardItem from '@uikit/items/CardItem';
import CostPerDay from '@uikit/items/CentreItem/CostPerDay';
import ServiceItemBanner from '@uikit/items/ServiceItemSecond/ServiceItemBanner';
import { ServiceItemStyles } from './styles';

interface Props {
  item?: Partial<CentreItem>;
  fullWidth?: boolean;
  isExistOnSlide?: boolean;
}

const { Meta } = Card;

const ServiceItem = ({
  item = {},
  fullWidth = true,
  isExistOnSlide = true,
}: Props) => (
  <CardItem fullWidth={fullWidth} isExistOnSlide={isExistOnSlide}>
    <Link href={getLinkCentreDetail(item)}>
      <ServiceItemStyles>
        <Card
          hoverable
          cover={<ServiceItemBanner fullWidth={fullWidth} item={item} />}
        >
          {item?.brand?.logo && (
            <div className="service-item__avatar">
              <Avatar size={80} src={item.brand.logo} />
            </div>
          )}

          <Meta
            className="mt-30 mb-10"
            title={item?.bestService?.serviceType?.name}
          />

          <div className="service-item__info-item">
            <CentreAddressDistance item={item} isEllipsis />
          </div>

          <div className="service-item__info-item flex-center">
            <CentreServiceInfo
              centreValue={item?.bestService?.centreValueForMoney}
              valueClassName="service-item__info-item-value"
              className="flex-center"
            />

            <div className="ml-8 mr-8 text-highlight">&#9679;</div>
            <CostPerDay item={item} />
          </div>
        </Card>
      </ServiceItemStyles>
    </Link>
  </CardItem>
);

export default withTrackingImpression(ServiceItem);
