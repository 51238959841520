export const LOGO_FEATURES_DATA: string[] = [
  '/images/features/logo-today-extra.png',
  '/images/features/logo-7-news.png',
  '/images/features/logo-news.png',
  '/images/features/logo-daily-telegraph.png',
  '/images/features/logo-herald-sun.png',
  '/images/features/logo-courier-mail.png',
  '/images/features/logo-the-advertiser.png',
  '/images/features/logo-nt-news.png',
  '/images/features/logo-sector-final-on-white.png',
  '/images/features/logo-mercury.png',
  '/images/features/logo-bulletin.png',
  '/images/features/logo-advertiser.png',
  '/images/features/logo-observer.png',
  '/images/features/logo-telegraph.png',
  '/images/features/logo-gold-coast-bulletin.png',
  '/images/features/logo-the-bulletin.png',
  '/images/features/logo-daily-mercury.png',
  '/images/features/logo-qt.png',
  '/images/features/logo-cairns-post.png',
  '/images/features/logo-sunshine-coast.png',
  '/images/features/logo-pilbara-news.png',
  '/images/features/logo-broome-advertiser.png',
  '/images/features/logo-northwest-telegraph.png',
  '/images/features/logo-great-southern-herald.png',
  '/images/features/logo-midwest-times.png',
];
