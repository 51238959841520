import { getIsLoadedTopCarouselHome } from '@redux/configLocal/selectors';
import { GREAT_OFFERS_PATH } from 'configs/constants';
import { useSelector } from 'react-redux';
import { useGetAllGreatOffersHomeQuery } from '@redux/advertisement/greatOffersAd';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import AdsSlide from './AdsSlide';

interface Props {
  isIgnoreTopLoading?: boolean;
}

const GreatOffersHomepage = ({ isIgnoreTopLoading }: Props) => {
  const isLoadedTopCarouselHome = useSelector(getIsLoadedTopCarouselHome);
  const currentGeoLocation = useSelector(getCurrentGeoLocation);

  const isSkipLoading = isIgnoreTopLoading ? false : !isLoadedTopCarouselHome;

  const { data, isLoading } = useGetAllGreatOffersHomeQuery(
    {
      location: { ...currentGeoLocation },
    },
    {
      skip: isSkipLoading,
      refetchOnMountOrArgChange: true,
    },
  );

  const viewAllParam = data?.onViewAllPress?.params;

  return (
    <AdsSlide
      eventPayload={data?.onViewAllPress?.loggingData}
      data={data?.results}
      title="home.greatOffers"
      subTitle="home.greatOffersDesc"
      path={{
        pathname: GREAT_OFFERS_PATH,
        query: {
          ...viewAllParam,
        },
      }}
      isLoading={isLoading || isSkipLoading}
    />
  );
};

export default GreatOffersHomepage;
