import { getUserParamsFilterSelector } from '@redux/auth/selectors';
import { useGetAllCentresNearbyPopularQuery } from '@redux/centresNearbyPopular';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { getIsLoadedTopCarouselHome } from '@redux/configLocal/selectors';
import { useSelector } from 'react-redux';
import CentreSlide from './CentresSlide';

interface Props {
  location?: {
    latitude: number;
    longitude: number;
  };
}

const PopularNearYou = ({ location }: Props) => {
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  const userParams = useSelector(getUserParamsFilterSelector);
  const isLoadedTopCarouselHome = useSelector(getIsLoadedTopCarouselHome);

  const { data, isLoading } = useGetAllCentresNearbyPopularQuery(
    {
      data: {
        limit: 12,
        offset: 0,
        ...(location ?? currentGeoLocation),
        ...userParams,
      },
      options: { isDefaultOrderBy: false },
    },
    {
      skip: !isLoadedTopCarouselHome,
    },
  );

  return (
    <CentreSlide
      title="home.popularNearYou"
      subTitle="home.popularNearYouDesc"
      path="/popular-childcare-centres-near-me"
      data={data}
      isLoading={isLoading || !isLoadedTopCarouselHome}
    />
  );
};

export default PopularNearYou;
