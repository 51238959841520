import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Link from '@uikit/commons/Link';
import useWindowSize from 'hooks/useWindowSize';
import { AustraliaFlagIcon, MoneyIcon } from '@uikit/commons/SVGIcons';
import {
  SubsidyCalculatorCTAStyles,
  SubsidyCalculatorContentStyles,
  SubsidyDescriptionStyle,
  SubsidyImgBannerStyle,
} from './styles';

const SubsidyCalculatorCTA = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  return (
    <SubsidyCalculatorCTAStyles className="position-relative">
      {isMobile && (
        <div>
          <SubsidyImgBannerStyle
            src="/images/subsidy/subsidy-banner.webp"
            alt="kindicare"
          />
          <AustraliaFlagIcon className="position-absolute bottom-0 " />
          <MoneyIcon className="position-absolute top-1/2 right-0 size-111" />
        </div>
      )}
      <>
        <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 100 }}>
          <Col lg={13} md={24} sm={24} xs={24}>
            <SubsidyCalculatorContentStyles className="position-relative">
              <div className="fw-700 size-32 lh-40 xs:size-20 xs:lh-30">
                {t('subsidyCalculator.title')}
                <span className="text-highlight ml-4 size-14">&#9679;</span>
              </div>
              <SubsidyDescriptionStyle className="mb-20 fw-400 size-16 lh-32 text-secondary xs:size-14">
                {t('subsidyCalculator.subsidyCalculatorCTADesc')}
              </SubsidyDescriptionStyle>

              <Link href="/child-care-subsidy-calculator">
                <Button
                  type="primary"
                  size={isMobile ? 'middle' : 'large'}
                  className="size-16 fw-bold w-209 xs:size-14 xs:w-176"
                >
                  {t('subsidyCalculator.calculateSubsidy')}
                  <ArrowRightOutlined className="ml-10" />
                </Button>
              </Link>
            </SubsidyCalculatorContentStyles>
          </Col>

          <Col
            lg={11}
            md={24}
            sm={24}
            xs={24}
            className="position-relative d-flex items-end justify-content-center"
          >
            {!isMobile && (
              <motion.div whileHover={{ scale: 1.1 }}>
                <img
                  src="/images/subsidy/subsidy-calculator-cta.png"
                  alt="kindicare"
                />
              </motion.div>
            )}
          </Col>
        </Row>
      </>
    </SubsidyCalculatorCTAStyles>
  );
};

export default SubsidyCalculatorCTA;
