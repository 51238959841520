import { Typography } from 'antd';
import { LOGO_FEATURES_DATA } from 'data/featuredByMockup';
import { useTranslation } from 'react-i18next';
import { FeaturedByStyles } from '@uikit/sections/FeaturedBy/styles';
import FeaturedByItems from '@uikit/sections/FeaturedBy/FeaturedByItems';
import Marquee from 'react-fast-marquee';
import React from 'react';
import useWindowSize from 'hooks/useWindowSize';
import FeaturedImage from '@uikit/sections/FeaturedBy/FeaturedImage';

export interface FeatureImage {
  logo: string;
}

const FeaturedBy = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <FeaturedByStyles>
      <Typography.Title className="text-center feature-title">
        {t('title.featuredBy')}
      </Typography.Title>
      {width > 992 ? (
        <>
          <FeaturedByItems items={LOGO_FEATURES_DATA.slice(0, 4)} />
          <FeaturedByItems items={LOGO_FEATURES_DATA.slice(5, 10)} />
          <FeaturedByItems items={LOGO_FEATURES_DATA.slice(11, 18)} />
          <FeaturedByItems items={LOGO_FEATURES_DATA.slice(19, 24)} />
        </>
      ) : (
        <div className="flex-center flex-column bg-gray">
          <div className="max-w-screen">
            <Marquee
              pauseOnHover
              speed={60}
              direction="left"
              gradient
              gradientWidth={0}
            >
              {LOGO_FEATURES_DATA?.map((item, index) => (
                <FeaturedImage logo={item} key={index} />
              ))}
            </Marquee>
          </div>
        </div>
      )}
    </FeaturedByStyles>
  );
};

export default FeaturedBy;
