import React from 'react';
import { useTranslation } from 'react-i18next';
import NextImage from '@uikit/commons/NextImage';
import useWindowSize from 'hooks/useWindowSize';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import {
  ChildcareMatchBannerStyles,
  ImageWrapper,
  TextSection,
  TextWithBottomLine,
  TextWithTopIcon,
} from './styles';

const ChildcareMatchBanner = () => {
  const { width } = useWindowSize();
  const { t } = useTranslation();

  return (
    <ChildcareMatchBannerStyles>
      {width < 992 && (
        <Button
          type="primary"
          className="z-1 mt-20 mb-30"
          href="https://get.kindicare.com/rk1K/yir3l0cj"
        >
          {t('button.downloadTheFreeApp')}
          <RightOutlined />
        </Button>
      )}
      <ImageWrapper>
        <img
          src="/images/banners/childcare-match-app-mockup.png"
          width={356}
          height={358}
          loading="lazy"
          alt="Childcare Match"
        />
      </ImageWrapper>
      <TextSection>
        <TextWithTopIcon>{t('childcareMatch.findYourPerfect')}</TextWithTopIcon>
        <TextWithBottomLine>
          {t('childcareMatch.childcareMatchOnThe')}
        </TextWithBottomLine>
        <div>KindiCare App!</div>

        {width >= 992 && (
          <div className="btn-download-app flex-center">
            <a href={process.env.NEXT_PUBLIC_DOWNLOAD_ANDROID_APP_URL}>
              <NextImage
                src="/images/download-app/btn-app-android.svg"
                width={151}
                height={45}
                alt="download app google play"
              />
            </a>
            <a
              href={process.env.NEXT_PUBLIC_DOWNLOAD_IOS_APP_URL}
              className="ml-12"
            >
              <NextImage
                src="/images/download-app/btn-app-ios.svg"
                width={151}
                height={45}
                alt="download app appstore"
              />
            </a>
          </div>
        )}
      </TextSection>
      {width >= 992 && (
        <ImageWrapper>
          <NextImage
            src="/images/banners/qrcode-app.png"
            width={234}
            height={304}
            quality={100}
            priority
            alt="Download App QRCode"
          />
        </ImageWrapper>
      )}
    </ChildcareMatchBannerStyles>
  );
};

export default ChildcareMatchBanner;
