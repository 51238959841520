import FeatureTitle from '@uikit/commons/FeatureTitle';
import Slide from '@uikit/sections/Slide';
import {
  ADS_CARD_TYPE,
  CARD_SLIDE_SETTINGS,
  DEFAULT_ADS_DELAY_IMPRESSION_TRACKING,
} from 'configs/constants';
import CardItem from '@uikit/items/CardItem';
import { isEmpty, omit } from 'lodash-es';
import RenderComponent from 'components/forJobSeekers/Helpers/RenderComponent';
import { useDispatch } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import { HomeSectionStyles } from './styles';
import SkeletonCarousel from './SkeletonCarousel';

interface AdsSlideProps {
  data?: AdsCardProps[];
  title?: string;
  subTitle?: string;
  path?: string | object;
  isLoading?: boolean;
  eventPayload?: object;
}

const AdsSlide = ({
  data,
  title,
  subTitle,
  path,
  isLoading,
  eventPayload,
}: AdsSlideProps) => {
  const dispatch = useDispatch();
  const triggerTrackingEvent = () => {
    dispatch(trackingEvent(omit(eventPayload, '_typename')));
  };

  if (isLoading) return <SkeletonCarousel />;
  return (
    !isEmpty(data) && (
      <HomeSectionStyles>
        <FeatureTitle
          title={title}
          subTitle={subTitle}
          path={path}
          triggerTrackingEvent={triggerTrackingEvent}
        />

        <Slide
          className="slide-h-full"
          autoplay={false}
          isShadowItem
          {...CARD_SLIDE_SETTINGS}
        >
          {data?.map((item, index) => (
            <CardItem className="h-full" key={index}>
              {RenderComponent(
                {
                  _typename: ADS_CARD_TYPE,
                  ...item,
                  isParentApp: true,
                  delayImpression: DEFAULT_ADS_DELAY_IMPRESSION_TRACKING,
                },
                {
                  primaryColor: 'var(--primary)',
                  viewAllHref: path,
                },
              )}
            </CardItem>
          ))}
        </Slide>
      </HomeSectionStyles>
    )
  );
};

export default AdsSlide;
