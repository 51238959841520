import { getServerSidePropsGenerator } from '@redux/store';
import HomeContainer from 'components/home';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import HeadSeo from '@uikit/headers/HeadSeo';
import { SEO_VAR } from 'configs/seo';
import { HOST_NAME, NAMESPACES_I18N } from 'configs/constants';
import { SEARCH_BY_KEYWORD } from 'configs/localData';

const siteLinkSearchBoxHomepage = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: HOST_NAME,
  potentialAction: {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: `${HOST_NAME}/centres?searchType=${SEARCH_BY_KEYWORD}&q={search_term_string}`,
    },
    'query-input': 'required name=search_term_string',
  },
};

const Home = () => (
  <>
    <HeadSeo item={SEO_VAR.home} structuredData={siteLinkSearchBoxHomepage} />
    <HomeContainer />
  </>
);

export default Home;

export const getServerSideProps = getServerSidePropsGenerator(
  async ({ locale }) => ({
    ...(await serverSideTranslations(locale, NAMESPACES_I18N)),
    hasParentSidebar: true,
  }),
);
