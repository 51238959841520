import styled from 'styled-components';

export const ServiceItemStyles = styled.div`
  .ant-card-meta-title {
    font-weight: bold;
    font-size: 16px;
  }
  .ant-card-body {
    position: relative;
    text-align: center;
  }

  .service-item__info-item + .service-item__info-item {
    margin-top: 10px;
  }
  .service-item {
    &__avatar {
      position: absolute;
      top: -46px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    &__info-item {
      font-size: 14px;
      .anticon {
        margin-right: 10px;
        color: var(--primary);
      }
    }
  }
`;
