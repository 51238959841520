import NextImage from '@uikit/commons/NextImage';
import { Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';
import { DOWNLOAD_APP_URL } from 'configs/constants';
import { DownloadStyles } from './styles';

const DownloadApp = () => {
  const { t } = useTranslation();
  return (
    <DownloadStyles>
      <Row className="flex-center fw-700 title-logo text-center">
        <Col xl={24} className="flex-center">
          {t('provider.downloadApp.title')}
          <div className="mr-10 ml-10 image-logo">
            <NextImage
              src="/images/logo-kindi.png"
              alt="free-app"
              width={160}
              height={50}
            />
          </div>
          {t('provider.downloadApp.nextTitle')}
        </Col>
      </Row>
      <div className="text-center flex-center desc-app mt-10 fw-400 size-16">
        {t('description.freeApp')}
      </div>
      <div className="mt-50">
        <NextImage
          src="/images/app-free.png"
          width={1220}
          height={598}
          alt="free-app-banner"
          priority
        />
      </div>
      <div className="mt-50 flex-center">
        <NextImage
          src="/images/download-app/qr-code.png"
          width={136}
          height={137}
          alt="qr-code-kindicare-app"
        />
      </div>
      <div className="flex-center mt-30">
        <a href={DOWNLOAD_APP_URL}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="fw-600"
          >
            {t('button.downloadTheFreeApp')}
            <ArrowRightOutlined />
          </Button>
        </a>
      </div>
    </DownloadStyles>
  );
};

export default DownloadApp;
