import PropTypes from 'prop-types';
import { StarFilledIcon } from '@uikit/commons/SVGIcons';
import { TestimonialItemStyles } from './styles';

const TestimonialItem = ({ title, content, author, starCount = 5 }) => (
  <TestimonialItemStyles>
    <div>
      {[
        ...Array(starCount)
          .fill()
          .map((_, i) => (
            <StarFilledIcon className="star-icon" key={String(i)} />
          )),
      ]}
    </div>
    <h4 className="testimonial__title">{title}</h4>
    <p className="testimonial__content">{content}</p>
    <div className="testimonial__author">{author}</div>
  </TestimonialItemStyles>
);

TestimonialItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  author: PropTypes.string,
  starCount: PropTypes.number,
};

export default TestimonialItem;
