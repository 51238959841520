import { getUserParamsFilterSelector } from '@redux/auth/selectors';
import { useGetAllCentresWellKnownBrandsQuery } from '@redux/centresWellKnownBrands';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { getIsLoadedTopCarouselHome } from '@redux/configLocal/selectors';

import { useSelector } from 'react-redux';
import CentreSlide from './CentresSlide';

const WellKnownBrands = () => {
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  const userParams = useSelector(getUserParamsFilterSelector);
  const isLoadedTopCarouselHome = useSelector(getIsLoadedTopCarouselHome);

  const { data, isLoading } = useGetAllCentresWellKnownBrandsQuery(
    {
      data: {
        limit: 12,
        offset: 0,
        ...userParams,
        ...currentGeoLocation,
      },
      options: { isDefaultOrderBy: false },
    },
    {
      skip: !isLoadedTopCarouselHome,
    },
  );

  return (
    <CentreSlide
      title="home.wellKnownBrands"
      subTitle="home.wellKnownBrandsDesc"
      path="/well-known-centres"
      data={data}
      isLoading={isLoading || !isLoadedTopCarouselHome}
    />
  );
};

export default WellKnownBrands;
