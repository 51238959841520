import { useTranslation } from 'react-i18next';
import {
  CHILDCARE_PROVIDER_ITEM,
  MAX_LIMIT_PER_PAGE,
  SEARCH_BY_BRAND_PATH,
} from 'configs/constants';
import NextImage from '@uikit/commons/NextImage';
import Slide from '@uikit/sections/Slide';
import { useGetAllBrandsQuery } from '@redux/brands';
import { useSelector } from 'react-redux';
import {
  getCurrentGeoLocation,
  getRehydratedPersistConfig,
} from '@redux/config/selectors';
import Link from '@uikit/commons/Link';
import { DEFAULT_CENTRE_COVER } from 'configs/localData';
import { CoveringProvidersStyles, ProviderLogoStyles } from './styles';

interface Props {
  className?: string;
}

const BRAND_NUMBER_TO_INFINITE = 7;

const CoveringProviders = ({ className }: Props) => {
  const { t } = useTranslation();

  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  const persistConfig = useSelector(getRehydratedPersistConfig);

  const { data } = useGetAllBrandsQuery(
    {
      data: {
        offset: 0,
        limit: MAX_LIMIT_PER_PAGE,
        ...currentGeoLocation,
      },
      options: { isDefaultOrderBy: false },
    },
    {
      skip: !persistConfig,
    },
  );

  const hrefBranchCenter = (item) => ({
    pathname: SEARCH_BY_BRAND_PATH,
    query: {
      brandId: item?.id,
    },
  });

  return (
    <CoveringProvidersStyles className={className || ''}>
      {data?.results?.length && (
        <div className="covering-providers">
          <div className="wrapper-container">
            <h2 className="covering-title lh-40 size-32">
              {t('title.coveringProvidersCentres')}
            </h2>
            <Slide
              autoplay
              infinite={data?.results?.length > BRAND_NUMBER_TO_INFINITE}
              className="slide-custom"
              {...CHILDCARE_PROVIDER_ITEM}
              lazyLoad="anticipated"
            >
              {data.results.map((item) => (
                <Link href={hrefBranchCenter(item)} prefetch={false}>
                  <ProviderLogoStyles>
                    <NextImage
                      width="80"
                      height="80"
                      src={item?.logo}
                      alt="kindicare"
                      key={String(item?.id)}
                      fallbackSrc={DEFAULT_CENTRE_COVER}
                    />
                  </ProviderLogoStyles>
                </Link>
              ))}
            </Slide>
          </div>
        </div>
      )}
    </CoveringProvidersStyles>
  );
};

export default CoveringProviders;
