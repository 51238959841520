import styled from 'styled-components';

export const CoveringProvidersStyles = styled.div`
  --section-padding-bottom: 200%;
  --section-bottom: 80%;
  --covering-providers-padding: 80px 0 30px 0;
  --slick-list-max-width: 90%;

  background: var(--bg-pastel-blue);
  width: 100%;
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    width: 400%;
    height: 0;
    padding-bottom: var(--section-padding-bottom);
    border-radius: 100%;
    background: #ffff;
    position: absolute;
    bottom: var(--section-bottom);
    left: 50%;
    transform: translateX(-50%);
  }
  .covering-providers {
    padding: var(--covering-providers-padding);
    img:not(:hover) {
      filter: grayscale(100%);
      opacity: 0.8;
    }
  }

  .ant-carousel .slick-slider .slick-list {
    max-width: var(--slick-list-max-width);
    margin: auto;
  }

  .covering-title {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 60px;
    font-weight: bold;
  }

  @media only screen and (max-width: 992px) {
    --section-padding-bottom: 400%;
    --section-bottom: 80%;

    .ant-carousel .slick-slider .slick-list {
      --slick-list-max-width: 90%;
    }
  }

  @media only screen and (max-width: 768px) {
    --section-padding-bottom: 300%;
    --section-bottom: 85%;
    --covering-providers-padding: 20px 0 30px 0;

    .ant-carousel .slick-slider .slick-list {
      --slick-list-max-width: 85%;
    }
  }

  @media only screen and (max-width: 576px) {
    --section-padding-bottom: 200%;
    --section-bottom: 90%;
    --covering-providers-padding: 50px 0 20px 0;

    .covering-title {
      font-size: 20px;
      line-height: 30px;
      margin: 20px 0 12px 0;
    }
    .ant-carousel .slick-slider .slick-list {
      --slick-list-max-width: 100%;
    }
  }

  @media only screen and (max-width: 480px) {
    --section-padding-bottom: 350%;
    --section-bottom: 85%;
    --covering-providers-padding: 40px 0 30px 0;

    .slide-custom {
      .slick-prev__custom {
        display: none !important;
      }
      .slick-next {
        display: none !important;
      }
    }
  }
`;

export const CoveringCentresStyles = styled.div`
  background: var(--bg-pastel-blue);
  padding: 30px 0 80px 0;

  @media only screen and (max-width: 425px) {
    .ant-col {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .custom-col-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
`;

export const CoveringItemStyles = styled.div`
  @media only screen and (max-width: 425px) {
    .covering-item-title {
      font-size: 16px;
    }

    .covering-item-link {
      font-size: 14px !important;
    }
  }
`;

export const ProviderLogoStyles = styled.div`
  & > span > span {
    background-color: var(--bg-pastel-blue) !important;
  }
  & > span > img {
    mix-blend-mode: multiply;
    margin: 0 15px;
  }
  img {
    border-radius: 50%;
    object-fit: cover;
  }
`;
