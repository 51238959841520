import React from 'react';
import { Col } from 'antd';
import { FeatureImage } from '@uikit/sections/FeaturedBy/index';

const FeaturedImage = ({ logo }: FeatureImage) => (
  <Col className="feature-image">
    <img src={logo} alt="kindicare-features" className="item-img" />
  </Col>
);

export default FeaturedImage;
