export const CAPITAL_CITIES_DATA = [
  {
    id: 1,
    city: 'Sydney',
    state: 'NSW',
    postCode: '2000',
  },
  {
    id: 2,
    city: 'Canberra',
    state: 'ACT',
    postCode: '2601',
  },
  {
    id: 3,
    city: 'Melbourne',
    state: 'VIC',
    postCode: '3000',
  },
  {
    id: 4,
    city: 'Brisbane',
    state: 'QLD',
    postCode: '4000',
  },
  {
    id: 5,
    city: 'Adelaide',
    state: 'SA',
    postCode: '5000',
  },
  {
    id: 6,
    city: 'Perth',
    state: 'WA',
    postCode: '6000',
  },
  {
    id: 7,
    city: 'Hobart',
    state: 'TAS',
    postCode: '7000',
  },
  {
    id: 8,
    city: 'Darwin',
    state: 'NT',
    postCode: '800',
  },
];
