import CoveringProvidersCentres from '@uikit/sections/CoveringProvidersCentres';
import { Skeleton } from 'antd';
import dynamic from 'next/dynamic';
import DownloadApp from '@uikit/sections/DownloadApp';
import FeaturedBy from '@uikit/sections/FeaturedBy';
import BusinessAwards from '@uikit/sections/BusinessAwards';
import SubsidyCalculatorCTA from '@uikit/banners/SubsidyCalculatorCTA';
import RequiredAuthSection from 'components/rest/RequiredAuthSection';
import Testimonials from '@uikit/sections/Testimonials';
import BackToTopButton from '@uikit/button/BackToTopButton';
import ChildcareMatchBanner from '@uikit/banners/ChildcareMatchBanner';
import ChildcareNearby from './ChildcareNearby';
import TopPicks from './TopPicks';
import FeaturedSpecialOffers from './FeaturedSpecialOffers';
import WellKnownBrands from './WellKnownBrands';
import PopularNearYou from './PopularNearYou';
import RecentlyViewed from './RecentlyViewed';
import CentresContacted from './CentresContacted';
import CentresFavourites from './CentresFavourites';
import SearchByBrand from './SearchByBrand';
import ArticlesStories from './ArticlesStories';
import RecommendedServices from './RecommendedServices';
import FeaturedProperties from './FeaturedProperties';
import GreatOffersHomepage from './GreatOffersHomepage';

const HomeBannerSearch = dynamic(
  () => import('@uikit/banners/HomeBannerSearch'),
  {
    loading: () => <Skeleton />,
  },
);

const Home = () => (
  <div>
    <div className="wrapper-container">
      <HomeBannerSearch />
      <ChildcareMatchBanner />
      <SubsidyCalculatorCTA />
      <SearchByBrand />
      <ChildcareNearby />
      <TopPicks />
      <FeaturedSpecialOffers />
      <ArticlesStories />
      <WellKnownBrands />
      <PopularNearYou />

      <GreatOffersHomepage />

      <RequiredAuthSection>
        <RecentlyViewed />
      </RequiredAuthSection>

      <RecommendedServices />

      <RequiredAuthSection>
        <CentresContacted />
      </RequiredAuthSection>

      <FeaturedProperties />

      <RequiredAuthSection>
        <CentresFavourites />
      </RequiredAuthSection>

      <Testimonials />
      <DownloadApp />
      <BusinessAwards />
    </div>
    <FeaturedBy />
    <CoveringProvidersCentres />
    <BackToTopButton color="var(--primary)" />
  </div>
);

export default Home;
