import styled from 'styled-components';

export const ServiceItemStyles = styled.div`
  .selected-card-item {
    border: 1px solid var(--primary);
  }
  .centre-item {
    &__content {
      font-size: 14px;
      > div:not(:first-child) {
        margin-top: 12px;
      }
    }

    &__info-item {
      display: flex;
      align-items: center;
      line-height: 16px;
      .anticon {
        margin-right: 10px;
        color: var(--primary);
      }
      &-value {
        color: var(--text-primary);
      }
    }
  }

  .ant-card-meta-title {
    font-weight: bold;
    font-size: 16px;
  }
  .ant-card-body {
    padding-top: ${({ showLogo }) => (showLogo ? '40px !important' : '15px')};
  }
`;

export const BannerCentreItemStyles = styled.div`
  .centre-item {
    &__cover-wrapper {
      position: relative;
    }
    &__rating-btn {
      background: var(--primary);
      font-size: 14px !important;
    }
    &__rating-img {
      width: 100%;
    }

    &__favorite {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      background-color: #2d1f2120;
    }
    &__NQSRType-img {
      width: 64px;
      height: 32px;
      object-fit: contain;
      background: #ffffff;
      border-radius: 6px;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
    &__logo {
      background: #ffffff;
      position: absolute;
      bottom: -30px;
      left: 30px;
      border: 4px solid var(--border-color-light);
    }
    &__group-tag {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  .tag-custom {
    height: 36px !important;
  }

  .block-btn {
    color: #fff;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    .anticon {
      font-size: 18px !important;
      line-height: 0;
    }
  }
`;
