import { BackToTopIcon } from '@uikit/commons/SVGIcons';
import { CSSProperties, useEffect, useState } from 'react';
import { isMobile } from 'utils/tools';
import { BackToTopButtonStyle } from '@uikit/button/BackToTopButton/styles';

interface Props {
  color: string;
  style?: CSSProperties;
  isShowBookTourBtn?: boolean;
}

const BackToTopButton = ({ color, style, isShowBookTourBtn }: Props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handle = () => {
      setVisible(window.pageYOffset > 600);
    };
    window.addEventListener('scroll', handle);
    return () => {
      window.removeEventListener('scroll', handle);
    };
  }, []);

  const backToTop = (e: MouseEvent) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };
  return (
    visible && (
      <BackToTopButtonStyle
        className="position-fixed flex-center z-100"
        isMobile={isMobile()}
        onClick={backToTop}
        isShowBookTourBtn={isShowBookTourBtn}
        color={color}
        style={style}
        aria-current="true"
        href="#"
      >
        <BackToTopIcon />
      </BackToTopButtonStyle>
    )
  );
};

export default BackToTopButton;
