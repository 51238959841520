import { getUserParamsFilterSelector } from '@redux/auth/selectors';
import { useGetAllCentresTopPicksQuery } from '@redux/centresTopPicks';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { geIsLoadedCarouselByKey } from '@redux/configLocal/selectors';
import { setCarouselHomeLoadedByKey } from '@redux/configLocal/slice';
import { CAROUSEL_HOME_KEYS, TOP_PICKS_PATH } from 'configs/constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CentreSlide from './CentresSlide';

interface Props {
  location?: {
    latitude: number;
    longitude: number;
  };
}

const TopPicks = ({ location }: Props) => {
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  const userParams = useSelector(getUserParamsFilterSelector);
  const dispatch = useDispatch();
  const isLoadedBeforeSlide = useSelector((state) =>
    geIsLoadedCarouselByKey(state, CAROUSEL_HOME_KEYS.childcareNearby),
  );

  const { data, isLoading, isSuccess, isError } = useGetAllCentresTopPicksQuery(
    {
      data: {
        limit: 12,
        offset: 0,
        ...userParams,
        ...(location ?? currentGeoLocation),
      },
      options: { isDefaultOrderBy: false },
    },
    {
      skip: !isLoadedBeforeSlide,
    },
  );

  useEffect(() => {
    if (isSuccess || isError) {
      dispatch(
        setCarouselHomeLoadedByKey({
          key: CAROUSEL_HOME_KEYS.topPicks,
          value: true,
        }),
      );
    }
  }, [dispatch, isSuccess, isError]);

  return (
    <CentreSlide
      title="home.topPicks"
      subTitle="home.topPicksDesc"
      path={TOP_PICKS_PATH}
      data={data}
      isLoading={isLoading || !isLoadedBeforeSlide}
    />
  );
};

export default TopPicks;
