import FeatureTitle from '@uikit/commons/FeatureTitle';
import Slide from '@uikit/sections/Slide';
import DomainItem from '@uikit/items/ResidentialItem';
import { useGetAllResidentialQuery } from '@redux/residential';
import { useSelector } from 'react-redux';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { FEATURE_PROPERTIES_CARD_SLIDE_SETTINGS } from 'configs/constants';
import { getIsLoadedTopCarouselHome } from '@redux/configLocal/selectors';
import { HomeSectionStyles } from './styles';
import SkeletonCarousel from './SkeletonCarousel';

const FeaturedProperties = () => {
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  const isLoadedTopCarouselHome = useSelector(getIsLoadedTopCarouselHome);

  const { data, isLoading } = useGetAllResidentialQuery(
    {
      data: {
        offset: 0,
        limit: 12,
        ...currentGeoLocation,
      },
      options: { isDefaultOrderBy: false },
    },
    {
      skip: !isLoadedTopCarouselHome,
    },
  );

  if (isLoading || !isLoadedTopCarouselHome) return <SkeletonCarousel />;

  return data?.listings?.length ? (
    <>
      <HomeSectionStyles>
        <FeatureTitle
          title="home.featuredProperties"
          subTitle="home.featuredPropertiesDesc"
          path="/featured-properties"
          isDomain
        />

        <Slide
          autoplay={false}
          isShadowItem
          {...FEATURE_PROPERTIES_CARD_SLIDE_SETTINGS}
        >
          {data?.listings?.map((item, idx) => (
            <DomainItem item={item} key={String(idx)} />
          ))}
        </Slide>
      </HomeSectionStyles>
    </>
  ) : null;
};

export default FeaturedProperties;
