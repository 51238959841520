import styled from 'styled-components';

export const BusinessAwardStyles = styled.div`
  margin-top: 120px;
  .desc-color {
    color: var(--text-secondary);
    margin-top: 40px;
  }

  @media only screen and (max-width: 576px) {
    margin-top: 40px;
  }
`;
