import {
  getCurrentUserSelector,
  getUserParamsFilterSelector,
} from '@redux/auth/selectors';
import { useGetAllCentresRecommendServicesQuery } from '@redux/centresRecommendServices';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { getIsLoadedTopCarouselHome } from '@redux/configLocal/selectors';
import FeatureTitle from '@uikit/commons/FeatureTitle';
import ServiceItem from '@uikit/items/ServiceItem';
import Slide from '@uikit/sections/Slide';
import { CARD_SLIDE_SETTINGS } from 'configs/constants';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { EVENT_TRACKING } from 'configs/eventTracking';
import SkeletonCarousel from './SkeletonCarousel';
import { HomeSectionStyles } from './styles';

const RecommendedServices = () => {
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  const userParams = useSelector(getUserParamsFilterSelector);
  const isLoadedTopCarouselHome = useSelector(getIsLoadedTopCarouselHome);
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUserSelector);
  const { data, isLoading } = useGetAllCentresRecommendServicesQuery(
    {
      data: {
        offset: 0,
        limit: 12,
        ...userParams,
        ...currentGeoLocation,
      },
      options: { isDefaultOrderBy: false },
    },
    {
      skip: !isLoadedTopCarouselHome,
    },
  );

  if (isLoading || !isLoadedTopCarouselHome) return <SkeletonCarousel />;

  return data?.results?.length ? (
    <HomeSectionStyles>
      <FeatureTitle
        title="home.recommendedServices"
        subTitle="home.recommendedServicesDesc"
        path="/recommended-childcare-centres"
      />

      <Slide autoplay={false} isShadowItem {...CARD_SLIDE_SETTINGS}>
        {data?.results?.map((item, index) => (
          <ServiceItem
            item={item}
            key={item?.id}
            impression={{
              loggingData: {
                eventName:
                  EVENT_TRACKING.CENTRES.IMPRESSION
                    .centreImpressionLandingPageCarousel,
                eventData: {
                  carouselName: t('home.recommendedServices'),
                  appearingIndex: index,
                  userId: currentUser.id || null,
                  centreId: item?.id,
                },
              },
            }}
          />
        ))}
      </Slide>
    </HomeSectionStyles>
  ) : null;
};

export default RecommendedServices;
