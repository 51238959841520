import styled from 'styled-components';

export const ChildcareMatchBannerStyles = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 32px;
  background: var(--pink-for-families-100, #fff0fb);
  overflow: hidden;
  margin-bottom: 80px;
  padding-top: 30px;
  padding-bottom: 6px;

  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    height: 170px;
    background-image: url('/images/banners/pink-cloud-background.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .btn-download-app {
    margin: 35px 0 0 7px;
    display: flex;
  }

  @media only screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }

  @media only screen and (max-width: 576px) {
    margin-bottom: 40px;
  }
`;

export const ImageWrapper = styled.div`
  flex-basis: 33.33%;
  position: relative;
  z-index: 1;
  margin: 0 36px;
  display: flex;
  justify-content: center;

  img {
    object-fit: cover;
  }

  @media only screen and (max-width: 992px) {
    margin: 0;
  }

  @media only screen and (max-width: 576px) {
    img {
      width: 228px;
      height: 239px;
    }
  }
`;

export const TextSection = styled.div`
  flex-basis: 33.33%;
  position: relative;
  z-index: 1;
  color: #170f49;
  text-align: center;
  font-size: 32px;
  line-height: 1.5;
  font-weight: 700;
  white-space: nowrap;

  @media only screen and (max-width: 1130px) {
    font-size: 26px;
  }

  @media only screen and (max-width: 992px) {
    font-size: 32px;
    margin-bottom: 27px;
  }

  @media only screen and (max-width: 576px) {
    font-size: 20px;
  }
`;

export const TextWithTopIcon = styled.div`
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: -34px;
    left: -2px;
    height: 72px;
    width: 72px;
    background-image: url('/images/banners/ic-search-normal.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;

    @media only screen and (max-width: 992px) {
      top: -16px;
      left: 16px;
      height: 48px;
      width: 48px;
    }

    @media only screen and (max-width: 576px) {
      top: -20px;
      left: -2px;
    }
  }
`;

export const TextWithBottomLine = styled.div`
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    bottom: -21px;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('/images/banners/line-vector.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    max-width: 238px;

    z-index: -1;
    @media only screen and (max-width: 1130px) and (min-width: 992px) {
      max-width: 195px;
      bottom: -18px;
    }

    @media only screen and (max-width: 576px) {
      max-width: 150px;
      bottom: -13px;
    }
  }
`;
