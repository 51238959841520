import styled from 'styled-components';

export const BackToTopButtonStyle = styled.a<{
  isMobile: boolean;
  color: string;
  isShowBookTourBtn: boolean;
}>`
  bottom: ${(props) => {
    if (props.isMobile) {
      return props.isShowBookTourBtn ? '130px' : '85px';
    }
    return '24px';
  }};

  right: 16px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #ebecf0;
  font-size: 20px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(6px);
  color: ${(props) => props.color};

  &:hover {
    color: ${(props) => props.color};
  }
`;
