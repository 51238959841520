import styled from 'styled-components';
import { Avatar } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { SEARCH_BY_BRAND_PATH } from 'configs/constants';
import Link from '@uikit/commons/Link';
import { getLinkJobsByBrand } from 'utils/tools';
import { Organization } from 'types/brands';
import CoverImageRatio from './CoverImageRatio';

interface Props {
  item?: Organization;
  fullWidth?: boolean;
  isExistOnSlide?: boolean;
  size?: number;
  isJobFeaturedBrands?: boolean;
}

const BrandItemStyles = styled.div`
  .ant-avatar,
  .cover-image__ratio {
    border: 2px solid var(--border-color-light);
  }

  .ant-avatar {
    background: var(--bg-content);
  }

  padding: ${({ isExistOnSlide }) => (isExistOnSlide ? '0 8px' : 'unset')};

  .cover-image__ratio {
    border-radius: 50%;
  }

  @media only screen and (max-width: 480px) {
    padding: ${({ isExistOnSlide }) => (isExistOnSlide ? '0 6px' : 'unset')};
  }
`;

const BrandItem = ({
  item,
  fullWidth = true,
  isExistOnSlide = true,
  size = 64,
  isJobFeaturedBrands,
}: Props) => {
  const href = isJobFeaturedBrands
    ? getLinkJobsByBrand(
        {
          ...item,
          brand: item?.name,
        },
        { extra: null, extraQuery: null },
        item?.id,
      )
    : {
        pathname: SEARCH_BY_BRAND_PATH,
        query: {
          brandId: item.id,
        },
      };

  return (
    <Link href={href}>
      <BrandItemStyles
        isExistOnSlide={isExistOnSlide}
        className="pointer brand-item"
      >
        {fullWidth ? (
          <CoverImageRatio
            ratioClassName="aspect-ratio-1-1"
            src={item?.logo}
            fullWidth={fullWidth}
            height={120}
          />
        ) : (
          <Avatar size={size} src={item?.logo} icon={<PictureOutlined />} />
        )}
      </BrandItemStyles>
    </Link>
  );
};

export default BrandItem;
