import { getUserParamsFilterSelector } from '@redux/auth/selectors';
import { useGetAllCentresRecentlyQuery } from '@redux/centresRecently';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { getIsLoadedTopCarouselHome } from '@redux/configLocal/selectors';
import { useSelector } from 'react-redux';
import CentreSlide from './CentresSlide';

const RecentlyViewed = () => {
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  const userParams = useSelector(getUserParamsFilterSelector);
  const isLoadedTopCarouselHome = useSelector(getIsLoadedTopCarouselHome);

  const { data, isLoading } = useGetAllCentresRecentlyQuery(
    {
      data: {
        limit: 12,
        offset: 0,
        ...userParams,
        ...currentGeoLocation,
      },
      options: { isDefaultOrderBy: false },
    },
    {
      skip: !isLoadedTopCarouselHome,
    },
  );

  return (
    <CentreSlide
      title="home.recentlyViewed"
      subTitle="home.recentlyViewedDesc"
      path="/recently-view-centres"
      data={data}
      isLoading={isLoading || !isLoadedTopCarouselHome}
    />
  );
};

export default RecentlyViewed;
