export const TESTIMONIALS_DATA = [
  {
    title: 'Fantastic platform for early childhood services',
    content:
      'KindiCare definitely know their craft and are in this for the right reasons. Thank you for supplying us and families with an easy to use and functional App that supplies updated information. Super easy and convenient to use.',
    author: 'Beaconsxx',
    starCount: 5,
  },
  {
    title: 'Easy to use app! Love the subsidy calculator',
    content:
      'Subsidy calculator allows me to search for childcare centres that are within my budget and are close to my home. Also love the quality ratings on centres!! Love this App.',
    author: 'bellaj81',
    starCount: 5,
  },
  {
    title: 'Easy to use with tonnes of value',
    content:
      'Finally an easy to use app to help sort through all the childcare providers. Love the score and comments, but the added benefit of being able to communicate with providers through the app is sensational.',
    author: 'JBA073',
    starCount: 5,
  },
];
