import { baseApi } from '@redux/@rtkQuery/baseApi';

export const greatOffersAdApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllGreatOffersHome: builder.query({
      query: (params) => ({
        url: '/thor/v2/parent/ads/posts/carousel/home',
        params,
      }),
    }),
    getAllGreatOffersCentreDetail: builder.query({
      query: (params) => ({
        url: `/thor/v2/parent/ads/posts/carousel/centre-detail`,
        params,
      }),
    }),
    getAllGreatOffersList: builder.query({
      query: (params) => ({
        url: '/thor/v2/parent/ads/posts',
        params,
      }),
    }),
    getRandomAd: builder.query({
      query: (params) => ({
        url: '/thor/v2/parent/ads/posts/random',
        params,
      }),
    }),
  }),
});

export const {
  useGetAllGreatOffersHomeQuery,
  useGetAllGreatOffersCentreDetailQuery,
  useGetAllGreatOffersListQuery,
  useGetRandomAdQuery,
} = greatOffersAdApi;
