import styled from 'styled-components';
import { Carousel } from 'antd';

export const TestimonialsStyles = styled.div`
  .stacking-context {
    position: relative;
    z-index: 1;
  }

  .testimonial-wrapper {
    position: relative;
    margin-top: 70px;
    background-color: var(--bg-pastel-blue);
    border-radius: 24px;

    &::after {
      content: '';
      position: absolute;
      top: -16px;
      bottom: -16px;
      right: -16px;
      border: 1px solid #cbd2d9;
      border-radius: 24px;
      width: 364px;
      z-index: -1;
    }
  }

  .quote-up {
    position: absolute;
    top: 57px;
    transform: translateX(-50%);
    display: flex;
    color: var(--primary);
    font-size: 3.4em;
    padding: 11px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
      0px 10px 16px rgba(20, 37, 63, 0.06);
    border-radius: 12px;
  }

  .testimonial-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0 125px 88px;

    .ant-carousel {
      flex-basis: 55%;
      max-width: 55%;
    }
  }

  .testimonial-banner {
    flex-basis: 45%;
    max-width: 45%;
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
  }

  @media only screen and (max-width: 1064px) {
    .testimonial-content {
      padding: 40px 0 105px 88px;
    }
  }

  @media only screen and (max-width: 992px) {
    .testimonial-content {
      padding: 40px 0 0 88px;
      .ant-carousel {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .testimonial-banner {
      margin-top: 60px;
      flex-basis: 100%;
      max-width: 100%;
      width: 100px;
      position: unset;
    }
  }

  @media only screen and (max-width: 576px) {
    .quote-up {
      font-size: 2em;
      padding: 6px;
    }
    .testimonial-content {
      padding-left: 45px;
    }

    .testimonial-banner {
      object-fit: cover;
      border-bottom-right-radius: 24px;
      margin-top: 75px;
    }

    .testimonial-wrapper {
      &::after {
        width: 300px;
      }
    }
  }
`;

export const TestimonialSlide = styled(Carousel)`
  .slick-list {
    transition: height 250ms linear;
    -webkit-transition: height 250ms linear;
  }

  .slick-prev,
  .slick-next {
    background: #fff !important;
    color: var(--color-grey-l3) !important;
    font-size: unset !important;
    width: 48px !important;
    height: 48px !important;
    margin-top: 0 !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    top: unset !important;
    bottom: -65px;
    z-index: 4;

    &:focus,
    &:hover {
      opacity: 0.8;
    }

    &::before {
      display: none;
    }

    .anticon {
      font-size: 19px;
    }
  }

  .slick-prev {
    left: 0 !important;
  }
  .slick-next {
    left: 70px !important;
  }
`;

export const TestimonialItemStyles = styled.div`
  color: var(--color-grey);
  padding-right: 88px;
  .star-icon {
    font-size: 33px;
    margin-right: 3px;
    transition: all 0.2s;

    &:hover {
      transform: translateY(-2px) scale(1.1);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }

  .testimonial {
    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      margin: 10px 0 12px;
    }

    &__content {
      font-weight: 400;
      font-size: 18px;
      line-height: 35px;
      margin-bottom: 16px;
    }

    &__author {
      position: relative;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-grey-l2);
      padding-left: 16px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 6px;
        background-color: #32a4fc;
        border-radius: 6px;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    padding-right: 45px;

    .testimonial {
      &__title {
        font-size: 18px;
      }

      &__content {
        font-size: 16px;
      }

      &__author {
        font-size: 14px;
      }
    }
  }
`;
